$header-border-color: $theme-light-gray;
$actions-text-color: $theme-light-gray;
$logo-width: 220px;
$actions-sm-width: 395px;
$navbar-zindex: 1000; /*5000*/
$actions-zindex:500; /*3000*/
$locations-dropdown-width: 320px;


#header {
  height: $header-height;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  @include position(relative);

  .logo{
    height: $header-height - 1;
    width: $logo-width;
    overflow: hidden;
    position: relative;

    img {
      margin-top:-160px;
      margin-left:-150px;
      height: 400px;
    }
  }

  .actions-wrapper {
    width: 500px;
    height:30px;
    text-align: center;
    border-bottom: 1px solid $header-border-color;
    margin:0;
    z-index: $actions-zindex;
    @include position(absolute, $top:0, $right:0);

    * {
      color: $actions-text-color;
      font-size: $nav-actions-text-size;
    }
    .action {
      height: 100%;

      &:not(:last-child){
        border-right: 1px solid $header-border-color;
      }
      .action-content{
        width: 100%;
        margin-left:-50%;
        @include position(absolute, $bottom:3px, $left:50%);
      }
      .fa-globe {
        font-size:20px;
        @include position(relative, $top:2px, $right:2px);
      }
    }

    .dropdown{
      .fa-caret-down {
        color: $theme-dark-gray;
        font-size:15px;
        @include position(relative, $top:1px,$right:2px)
      }
      .dropdown-menu {
        padding: 10px 15px;
        width: $locations-dropdown-width;
        margin-left: -166px;

        * {
          color: black;
        }
        .dropdown-item{
          display: block;
          &:not(:last-child):after{
            content: "";
            width: 90%;
            background: $theme-light-gray-w-opacity;
            height: 1px;
            display: block;
            margin: 10px auto;
          }
        }
      }
      .loc-summary{
        display: block;
        .loc-img{
          display: none;
        }
        .loc-details{
          display:block;
          margin-left:0;
        }
      }
    }
  }

  .navbar {
    background: none;
    border: none;
    margin: 0;
    text-align: center;
    @include position(absolute, $right:0, $bottom:0, $left:0);

    ul.navbar-nav {
      text-transform: uppercase;
      display: inline-block;
      float: none;
      vertical-align: top;

      * {
        font-size: $nav-text-size;
      }
      li {
        margin: 0 10px;
        z-index:100;
      }
      a {
        color: black;
        font-weight: bold;
        cursor: pointer;
      }
    }

    .icon-close {
      display:none;
      cursor: hand;
      font-size:20px;
      margin-top:5px;
      float:right;
      color: $theme-light-gray;
      @include position(absolute, $right:12px)
    }
    .navbar-toggle {
      border: none;
    }
    .icon-bar{
      width: 30px;
    }

    .submenu {
      .dropdown-menu {
        padding: 5px;

        > div {
          margin: 7px 0;
        }
      }
    }
    .selected-menu {
      color: $theme-red !important;
    }
  }

  @media (max-width: $header-media-between-md-lg) {
    height: $header-between-md-lg-height;

    .navbar {

      ul.navbar-nav li {
        margin: 0 5px;
      }
    }
  }

  @media #{$media-tablet}{
  }

  @media #{$media-tablet-n-phone} {
    height: $header-height;

    .actions-wrapper {
      width: $actions-sm-width;
      border-bottom: none;
      right: 35px;

      .action-content{
        top: 1px;
      }
    }
    .navbar {
      top: 0px;
      left: auto;
      .navbar-toggle {
        margin-right: 0px;
        margin-top:0px;
      }

      .navbar-collapse {
        z-index: $navbar-zindex;
        background: $theme-dark-gray;
        @include position(fixed, $top:0, $right:0, $bottom:0, $left:0);

        ul.navbar-nav  {
          margin-top:30px;

          a {
            color: $theme-light-gray;
            font-weight: 300;
          }
        }
        .icon-close {
          display:block;
        }
      }
    }
  }

  @media #{$media-phone} {
    height: $header-xs-height;

    .logo {
      @include position(relative, $top:18px, $left:10%);

      img {
        margin-top: -125px;
        margin-left: -120px;
        height: 300px;
      }
    }

    .actions-wrapper{
      width:150px;
      text-align: right;
      @include position(absolute, $top: 40px, $right: 10px);

      .action {
        border: none !important;
        height: 21px;
      }
    }
  }

  /* specific for location drop down on small devices, has own breakpoint */
  @media (max-width: $locations-dropdown-width + 20) {
    .dropdown{
      .dropdown-menu {
        width: 90% !important;
        margin-left: 0 !important;
        @include position(fixed, $top:60px,$left:5%);
      }
    }
  }
}


/* force navbar to display sooner on tablet DO NOT CHANGE */
@media #{$media-tablet} {
  .navbar-header {
    float: none;
  }
  .navbar-left,.navbar-right {
    float: none !important;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
    display: none!important;
  }
  .navbar-nav {
    float: none!important;
    margin-top: 7.5px;
  }
  .navbar-nav>li {
    float: none;
  }
  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collapse.in{
    display:block !important;
  }
}


