#pageContact{

  .contact-wrap {
    width: 80%;
    padding: 60px;
    margin-left: 10%;
    margin-bottom: 50px;
    box-shadow: 8px 8px 16px #95989A;
    position: relative;
    background: white;

    text-align:left;

    input:not([name='time']), textarea, select{
      width: 100%;
    }

    div.datetime-picker {
      width: 100%;
    }

    .field-title{
      margin-top:10px;
    }
  }

  .contact-footer{
    text-align:right;
    input[type=button]{
      width: 50px;
    }
  }

  .warning {

    line-height:initial;
    text-align:center;
    padding-bottom: 10px;

    &.failed {

    }
    &.required {

    }
  }

  .fa-spin {
    display: none;
    font-size:30px;
  }

  @media #{$media-phone}{
    .contact-wrap {
      width: 98%;
      padding: 15px;
      margin-left: 1%;
    }
  }
}

