$service-img-width: 80px;
$content-spacing: 7px;

#pageServices {

  .services-wrapper {
    width: 80%;
    margin: 0 auto;
  }

  .service {
    background: white;
    padding: 10px 0px;
    border: 2px solid transparent;

    &:hover {
      @include box-shadow(5px, 5px, 7px, #C7C7C7);
    }
    &.expanded {
      @include box-shadow(5px, 5px, 7px, #C7C7C7);
      border-color: transparent;
    }
    &:not(:first-child){
      margin-top:15px;
    }
    .img-wrap {
      display: inline-block;

      img {
        width: 100%;
      }
    }
    .content-wrap {
      display: inline-block;
      .title {
        font-size: $content-title-font-size;
        margin-bottom: $content-spacing;
      }
      .description {
        margin-bottom: $content-spacing;
      }
      .more {
        font-size: 11px;
        color: $theme-blue;
      }
      .item {
        display: none;
      }
      .item-title{
        margin-top: $content-spacing;
        margin-bottom: 5px;
        color: $theme-dark-gray;
        font-weight:500;
      }
      .item-desc {
        margin-bottom: 5px;
      }
      .item-bullet {
        margin-left: 15px;
        margin-bottom: 5px;
      }
      .less {
        font-size: 11px;
        color: $theme-red;
      }
    }
  }


  @media #{$media-tablet}{

  }

  @media #{$media-tablet-n-phone}{
    .services-wrapper {
      width: 100%;
    }
  }

  @media #{$media-phone}{

  }
}
