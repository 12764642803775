#pageScreening {
  .services-wrapper {
    margin: 0 60px;
    .service {
      position: relative;
      .title {
        margin-top:5px;
        text-align: center;
        font-size: $content-title-font-size;
      }
      .img {
        margin: 15px 0;
      }
      img {
        width: 90%;
        max-width: 150px;
        margin: 0 auto;
        display: block;
      }
      .desc {
        margin: 0 10px 15px 10px;
      }
      .border {
        position: absolute;
        background: black;
        width: 1px;
        top: 0;
        bottom: -10px;
        display: none;
        &.left {
          left: 0;
        }
        &.right{
          right: 0;
        }
        &.bottom {
          top: auto;
          left: 0;
          right: 0;
          bottom: 0;
          width: auto;
          height: 1px;
        }
      }
      &.service-index-1{
        .border.right, .border.left {
          display: block;
        }
      }
    }
  }

  @media #{$media-tablet}{
    .services-wrapper {
      .service {
        &.service-index-1 {
          .border.right, .border.left {
            display: none;
          }
        }
        &.service-index-0 {
          .border.right {
            display: block;
          }
        }
      }
    }
  }

  @media #{$media-tablet-n-phone}{
    .services-wrapper {
      margin: 0;
    }
  }

  @media #{$media-phone}{
    .service {
      .border.right, .border.left {
        display: none !important;
      }
      .border.bottom {
        display: block;
      }
    }
  }
}
