$footer-text-color: white;
$footer-font-weight: 200;

#footer {
  text-align: center;
  font-weight: $footer-font-weight;

  .footer-content {
    color: $footer-text-color;
    margin: 0;
    padding: 0 1%;
    background: $theme-dark-gray;
    height: $footer-height;

    .site-links {
      margin-top: 20px;
      font-weight: $footer-font-weight;
      .footerNav {
        margin-top: 6px;
      }
    }
    .social-content {
      margin-top: 20px;
      .footerCredit {
        margin-top: 10px;
      }
      .social-text {
        font-weight: $footer-font-weight;
      }
      .social-links {
        margin-top: 13px;
      }
      .social-icon {
        padding: 5px;
        margin: 5px;
        background: black;
        span {
          margin-top: 5px;
        }
      }
    }
    .copyright {
      margin-top: 15px;
      .footerCredit {
        margin-top: 10px;
      }
      .logo {
        overflow: hidden;
        height: 30px;
        width: 75px;
        margin: 0 auto;

        img {
          height: 150px;
          @include position(relative, $top: -61px, $left: -60px);
        }
      }
      .text-1 {
        text-align: right;
      }
      .text-2 {
        text-align: left;
      }
    }
    .privacy {
      margin-top: 20px;
    }
  }
  .copyrightFooter, .creditFooter, .footerCredit {
    font-size: 10px;
  }

  @media #{$media-tablet} {
    .footer-content {
      height: $footer-sm-height;
      .copyright {
        margin-top: 20px;
      }
      .copyrightFooter {
        margin-top: 15px;
      }
      .social-content {
        margin-top: 30px !important;
        .social-icon {
          margin: 3px !important;
        }
      }
      .site-links {
        margin-top: 20px !important;
      }
    }
  }

  @media #{$media-tablet-n-phone} {

  }

  @media #{$media-phone} {
      .footerNav {
        border-bottom: 1px solid white;
        padding-bottom: 7px;
        width: 70%;
        margin-left: 15%;
        &.privacy {
          border-bottom: none;
        }
      }
      .footer-content {
        height: $footer-xs-height;
        .social-content {
          margin-top: 0 !important;
          .social-icon {
            margin: auto 10px;
          }
        }
      }
      .copyright {
        .logo {
          margin-top: 10px !important;
        }
        .copyrightFooter {
          margin-top: 15px;
        }
      }
    }

}


/* *********** Code to position Footer at bottom of page. Do not change
  source: https://codepen.io/cbracco/pen/zekgx
*/
html {
  height: 100%;
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  position: relative;
  margin: 0;
  padding-bottom: $footer-height;
  min-height: 100%;

  @media #{$media-tablet} {
    padding-bottom: $footer-sm-height;
  }

  @media #{$media-phone} {
    padding-bottom: $footer-xs-height;
  }
}

#footer {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
}
/* END of footer code ********* */

