#pageAbout {
  @include side-pad(50px !important);

  .about-title {
    display:block;
    color:$theme-dark-gray;
    font-size: $page-sub-title-font-size;
    margin-bottom: 5px;
  }

  .about-text {
    padding-right: 40px;
    ///width: 60%;
    //display:inline-block;
  }

  .mission-text {
    //width: 35%;
    //float: right;
    //display:inline-block;
    background: white;
    padding: 15px 36px;
    @include box-shadow(8px, 8px, 16px, #95989A);
  }

  @media #{$media-tablet}{
    @include side-pad(20px !important);
  }

  @media #{$media-tablet-n-phone}{

  }

  @media #{$media-phone}{
    @include side-pad(10px !important);

    .mission-wrapper {
      padding-top: 25px;
    }
  }
}