#pageMedicalDirector {
  .medical-director-profile {
    width: 70%;
    margin: 0 auto;
  }
  .meet-header {
    background: $theme-blue;
    height: 70px;
    line-height: 70px;
    text-align:center;
    color:white;
    font-size: $content-title-font-size;
  }
  .content-wrap {
    background: $theme-light-gray-w-opacity;
    padding-bottom: 30px;
  }
  .img-wrap {
    img {
      display: block;
      margin: 0 auto;
      width: 100%;
      max-width: 250px;
    }
  }
  .details-wrap {
    margin-top: 10px;
    text-align:center;
    .doctor-name {
      font-size: $page-sub-title-font-size;
    }
    .title {
      font-size: $content-title-font-size;
      font-weight: bold;
    }
    .value {
      margin-left: 5px;
    }
  }

  @media #{$media-tablet}{
    .medical-director-profile {
      width: 90%;
    }
  }

  @media #{$media-tablet-n-phone}{

  }

  @media #{$media-phone}{
    .medical-director-profile {
      width: 100%;
    }
  }
}
