$locations-title-text-size: 20px;
$find-title-text-size: 15px;
$home-text-size: 20px;
$search-spacing: 20px;
$body-padding: 20px;

body .body-content.content-for-index {
  padding: 0 0;
  .pc-page .page {
    padding: 0 0 $pages-padding 0;
  }
}

#pageIndex {
  @include position(relative);
  padding-top: $pages-padding 0;


  .covid-alert {
    padding: 12px;
    margin:0;
    position: relative;
    margin-top:5px;
    .bkg {
      @include position(absolute, 0, 0, 0, 0);
      background: lightgray;
      opacity:.8;
      border: 3px solid #C70039;
    }
    .col-l {
      text-align: center;
      font-size:25px;
      font-weight:bold;
      padding-top:20px;
      i {
        font-size:60px;
        color:#C70039;
      }
    }

    .col-r {
      .main-t {
        font-weight:bold;
        font-size:18px;
      }
    }
  }

  .tile-img {
    overflow: hidden;
    margin: 0;
    margin-top: 3px;
    @include position(relative);

    img {
      width: 100%;
      @include opacity(0.7);
    }
    .home-text {
      font-weight: 500;
      width: 560px;
      @include position(absolute, $right: 15px, $bottom: 10px);

      .text-1 {
        color: $theme-red;
      }
      .text-1, .text-2 {
        font-size: $home-text-size;
        text-align: center;
      }
    }
    .meet-dr{
      @include position(absolute, 20%, auto, auto, 10px);
      padding: 10px;
      font-weight: bold;
      text-align: center;
      .meet-bk {
        background: white;
        opacity: .8;
        z-index: 90000;
        @include position(absolute, 0, 0, 0, 0);
      }
      .meet-content {
        position: relative;
        z-index: 90001;
      }
      input {
        margin-top: 10px;
      }
      img {
        width: 100px;
        margin-top: 0px;
      }
    }
  }

  .location-search {
    text-align: center;
    width: 285px;
    @include position(absolute, $top: 197px, $right: 0);

    > div {
      margin-top: 10px;
    }
    .title {
      margin-bottom: 10px;
      font-size: $find-title-text-size;
      font-weight: 500;
    }
    .address-view-all a {
      color: black;
    }
    .input-wrapper {
      position: relative;

      input[type=text]{
        width: 200px;
        margin-bottom: 10px;
      }
    }
  }

  .meet-dr-2 {
    height: 100px;
    text-align: center;
    font-weight: bold;
    background: rgba(15,15,15,.06);
    position:relative;
    top:-100px;

    .meet-txt {
      color: $theme-blue;
    }
    .f-left {
      height: 100px;
    }
    .middle-c {
      padding: 20px 5px 0 5px;
      height: 100px;
    }
    .f-right {
      padding-top:40px;
      padding-right: 10px;
      height: 100px;
    }
    img {
      height: 100px;
    }
  }

  .pc-badges-wrapper {
    margin: 10px $body-padding 0 $body-padding;

    > div:nth-child(odd) .pc-badge {
      background: $theme-blue;
    }
    > div:nth-child(even) .pc-badge {
      background: $theme-red-w-opacity;
    }

    .badge-link-wrapper {
      display: block;

      &:hover {
        text-decoration: none;
      }
      span {
        display: block;
      }
      &:hover .pc-badge {
        @include box-shadow(5px, 5px, 7px, $theme-light-gray);
      }
    }

    .pc-badge {
      height: 170px;
      padding: 10px;
      text-align: center;
      margin: 5px 10px;
      @include box-shadow(5px, 5px, 7px, #C7C7C7);

      .badge-icon {
        height: 30px;

        img {
          height: 100%;
        }
        .fa {
          font-size: 28px;
        }
      }
      .badge-text {
        margin-top: 5px;
      }
    }

    .subscribe-badge {
      input[type=text]{
        width: 85%;
        margin-top:5px;
        display:inline-block;

      }
      input[type=button]{
        margin-top:8px;
        display:inline-block;
      }
      .fa {
        font-size: 19px;
        margin-top: 10px;
        display:none;
      }
    }
  }

  .locations-wrapper {
    text-align: center;
    margin: 0 $body-padding;

    .locations-title {
      font-size: $locations-title-text-size;
      font-weight: 500;
      text-transform: uppercase;
      margin: 15px 0;
    }
    .locations-list {
      @include position(relative);

      .separator {
        height: 80%;
        width: 2px;
        background: $theme-light-gray-w-opacity;
        @include position(absolute, $top: 10%, $left: 50%);
      }
      .loc-summary {
        margin: 10px 0 10px 30px;
      }
    }
  }

  @media (max-width: $screen-sm) {
    .contactLogo {
      margin: 50px auto 40px auto;
    }
    h2, h3, ul {
      text-align: center;
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
    }

    input[type=text], input[type=email], select {
      font-size: $sm-input-font-size;
    }
  }

  @media #{$media-tablet}{

    .tile-img {
      .home-text {
        /*width: 420px;*/
      }
      img {
        margin-top: -58px;
      }
    }
    .pc-badges-wrapper {
      > div:nth-child(1) .pc-badge, > div:nth-child(4) .pc-badge {
        background: $theme-blue;
      }
      > div:nth-child(2) .pc-badge, > div:nth-child(3) .pc-badge {
        background: $theme-red-w-opacity;
      }
    }
  }

  @media #{$media-tablet-n-phone}{

    .location-search {
      position: relative;
      width: auto;
      background: $theme-light-gray;
      padding: 10px 23px;
      margin:0;
      top:2px;
      position:relative;

      > div {
        margin-top: 0px;
      }
      .title {
        margin-bottom: 10px;
      }
      .input-wrapper {
        input[type=text]{
          width: 200px;
        }
        input[type=button]{

        }
      }
    }
    .pc-badges-wrapper {
      .pc-badge {
        height: 150px;
      }
    }
    .locations-list {
      .loc-summary {
        margin-left: auto !important;
      }
    }
  }

  @media #{$media-phone}{

    .tile-img {
      img {
        @include opacity(.3);
      }
      .home-text {
        width: 98%;
        right: 1%;

        * {
          font-size: 14px !important;
        }
      }
    }
    .locations-wrapper {
      .locations-list {
        .loc-row > div:after {
          content: "";
          width: 90%;
          background: $theme-light-gray-w-opacity;
          height: 2px;
          display: block;
          margin: 10px auto;
        }
        .separator {
          display: none;
        }
      }
    }
  }
}

