$loc-header-height:35px;
$loc-img-height: 90px;
$loc-width: 70%;
$loc-search-height: 60px;

#pageLocations {
  padding-top: 0px;

  .locations-search{
    background: $theme-blue;
    width: $loc-width;
    margin: 20px auto;
    min-height: $loc-search-height;
    line-height: $loc-search-height;
    color: white;

    .text {
      text-align: right;
    }
    input[type='text']{
      width: 100%;
    }
    input[type='button']{
      display:inline-block;;
    }
    .btn {

    }
    .warning {
      display: none;
      line-height:initial;
      text-align:center;
      padding-bottom: 10px;

      &.failed {

      }
      &.required {

      }
    }
  }

  .fa-spin {
    display: none;
    font-size:30px;
    line-height: $loc-search-height;
  }

  .locations-wrapper {
  }

  .location {
    margin: 0 auto 40px auto;
    width: $loc-width;
    clear: both;

    .details {
      box-shadow: 8px 8px 16px #95989A;
      position: relative;
      background: white;
    }

    .header {
      background: $theme-red-w-opacity;
      height: $loc-header-height;
      line-height: $loc-header-height;

      .name {
        font-size: $content-title-font-size;
        font-weight: 500;
        color: black;
      }
      .distance {
        text-align: right;
        font-size: $content-title-font-size;
      }
    }

    .body {
      margin: 10px 130px 10px 20px;
      min-height: 188px;
      color: #95989A;
      position: relative;

      .address {
        padding-right: 7px !important;
      }
      .address, .contact-info {
        color:black;
      }
      .hours-type {
        font-weight: 700;
        padding-top: 10px;
      }
      .loc-actions {
        @include position(absolute, null, null, 5px);
        margin-top:10px;
        text-align:center;
      }
    }

    .addr-wrapper {
      position: relative;
      .separator {
        background: black;
        height: 2px;
        @include position(absolute, null, 100px, -5px, 0)
      }
    }

    .image {
      position: absolute;
      right: 20px;
      height: $loc-img-height;


      img {
        height: 100%;
      }
      &.store {
        top: $loc-header-height + 10px;
      }
      &.map {
        top: $loc-header-height + 10px + $loc-img-height + 10px;
        &:hover {
          opacity: .7;
        }
      }
    }
    .hours-group {
      margin-bottom: 50px;
    }
  }

  @media #{$media-tablet}{

  }

  @media #{$media-tablet-n-phone}{

  }


  @media #{$media-phone}{
    .locations-search {
      height: auto;
      width: 100%;
      text-align:center;
      line-height:initial;

      .text {
        text-align:center;
      }
      input[type='text'] {
        width: 80%;
      }
      .row > div {
        margin: 5px 0;
      }

    }
    .location {
      width: 100%;

      .body {
        margin: 10px 0px;
      }

      .separator {
        right: 0 !important;
      }
    }
  }

}
$gutter-padding: 10%;

.locations-modal {
  .form-type {
    text-transform: capitalize;
  }
  .modal-header {
    width: 100% - $gutter-padding - $gutter-padding;
    margin: 0 auto;
  }
  .header {
    text-align: left;
    width: 100% - $gutter-padding - $gutter-padding;
    margin: 0 auto;
  }
  .field-group {
    width: 100% - $gutter-padding - $gutter-padding;
    margin: 0 auto 10px auto;
    input[type='text'], input[type='calendar'], input[type='email'], textarea {
      width: 100%;
      margin-bottom: 0px;
    }
    .field-title {
      text-align: left;
      margin: 0 0 5px 0;
    }
  }
  .datetime-picker {
    input[type='text']{
      width: 100%;
    }
  }
  .modal-footer {
    padding-right: $gutter-padding;
  }
  @media #{$media-phone}{
      .modal-header, .header, .field-group {
        width: 94%;
      }
  }
}
