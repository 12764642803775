#pageBusiness {


  .bus-container {
    margin-top: 40px;
    .inner-bus {
      background-color: white;
      padding: 25px;
      text-align: center;
      height: 345px;
      box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
      .title {
        font-size: $content-title-font-size;
        font-weight: 400;
      }
      .icon {
        font-size: 40px;
      }
      .description {
        font-weight: 200;
        margin-bottom: 15px;
      }
      .more-link {
        font-size: ($body-font-size - 1);
        color: #009688;
        font-weight: 300;
      }
    }
  }

  .contactBox {
    background-color: #424242;
    height: 425px;
    box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    form {
      margin-top: 30px;
    }
    input {
     background-color: inherit;
      border: none;
      border-bottom: 2px solid #009688 !important;
      margin: 8px;
      width: 90%;
    }
    textarea {
      margin-top: 20px;
      margin-left: 10px;
      width: 90%;
      height: 110px;
      border: none;
    }
    .bus-box-title {
      text-align: center;
      color: white;
      font-size: $content-title-font-size;
      margin-bottom: 20px;
      font-weight: 300;
    }
    .send-button {
      text-align: right;
      border: none !important;
      color: #009688;
      font-weight: 200;
    }
  }

  @media #{$media-tablet}{
    .bus-container {
      .inner-bus {
        height: 100%;
        &.attorney-description, &.contactUs-description {
          border: 3px solid #DF5A5A;
        }
        &.testing-description {
          border: 3px solid #76B1CF;
        }
        .title-icon-cont {
          padding: 20px 27px 5px 0px;
        }
        .description {
          height: 110px;
          text-align: left;
          margin-bottom: 0;
        }

      }
    }
    .title-icon-cont {
      float: left;
    }
    .contactBox {
      margin-top: 20px;
    }
  }

  @media #{$media-tablet-n-phone}{


  }

  @media #{$media-phone}{
    .bus-container {
      .inner-bus {
        height: 100%;
        padding: 0 0 15px 0;
        &.attorney-description, &.contactUs-description {
          border: 3px solid #DF5A5A;
        }
        &.testing-description {
          border: 3px solid #76B1CF;
        }
        .title-icon-cont {
          padding: 10px;
        }
        .description {
          display: none;
        }

      }
    }
    .title-icon-cont {
    }
    .contactBox {
      margin-top: 20px;
    }
  }

}
