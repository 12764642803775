
/*
 * Bower sass dependencies
 */
$screen-xs: 0;
$screen-sm: 768px;
$screen-md: 992px;
$screen-lg: 1200px;

// bower:scss
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
// endbower

@import "bower_components/components-font-awesome/scss/font-awesome.scss";

/*
 * Global Variables
 */
$theme-light-gray: #95989A;
$theme-light-gray-w-opacity: rgba(149,152,154,0.55);
$theme-dark-gray: #534F4F;
$theme-red: #E93E3E;
$theme-red-w-opacity: rgba(233,62,62,0.78);
$theme-blue: #317FAC;
$body-gutters-size: 40px;
$body-gutters-size-phone: 10px;
$footer-top-margin: 20px;
$pages-padding: 20px;
$body-max-width: $screen-lg;
$header-height: 90px;
$header-xs-height: 110px;
$header-media-between-md-lg: 1320px;
$header-between-md-lg-height: 120px;
$footer-height: 90px;
$footer-xs-height: 250px;
$footer-sm-height: 120px;
//-font sizes
$body-font-size: 13px;
$button-font-size:12px;
$page-sub-title-font-size:20px;
$content-title-font-size:15px;
$sm-input-font-size:16px;
$nav-actions-text-size: 12px;
$nav-text-size:13px;

//media breakpoints
$media-phone: "(max-width: " + ($screen-sm - 1) + ")";
$media-tablet: "(min-width: " + $screen-sm  + ") and (max-width: " + ($screen-md - 1) + ")";
$media-tablet-n-phone: "(max-width: " + ($screen-md - 1) + ")";


/* mixins */
@import "mixins";



/*
 * Global Styles
 */

/* Font family / Font Size */
html *
{
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: $body-font-size;
}

/* body and content sizes */
body {
  min-width: 300px;
  overflow-x: hidden;

  #bodyBkg {
    margin: 0 auto;
    max-width: $body-max-width;
    background: rgba(15,15,15,.06); //#E9ECEF

    @include position(
      absolute,
      $top:$header-height,
      $right: $body-gutters-size,
      $bottom:$footer-height + $footer-top-margin,
      $left:$body-gutters-size
    );

    @media (max-width: $header-media-between-md-lg) {
      top: $header-between-md-lg-height;
    }

    @media #{$media-tablet-n-phone} {
      left: 0;
      right: 0;
    }

    @media #{$media-tablet} {
      @include position(
        absolute,
        $top: $header-height,
        $bottom: $footer-sm-height + $footer-top-margin
      );
    }
    @media #{$media-phone} {
      @include position(
        absolute,
        $top: $header-xs-height,
        $bottom: $footer-xs-height + $footer-top-margin
      );
    }
  }

  .body-content {
    padding: 0 $body-gutters-size;
    @include position(relative);

    .pc-page {
      max-width: $body-max-width;
      margin: 0 auto;
      padding-bottom: $footer-top-margin;

      .page {
        padding: $pages-padding;
      }
    }

    @media #{$media-tablet-n-phone} {
      .pc-page > div{
        padding-left:0;
        padding-right:0;
      }
    }
    @media #{$media-phone} {
      padding: 0 $body-gutters-size-phone;
    }
  }
}





/* grid layout custom sizes */
.row.row-no-padding {
  [class*="col-"] {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}


/* global link styles */
a {
  color: inherit;
  font-size: inherit;
  text-decoration: none;
  background: inherit !important;

  &.underlined {
    text-decoration: underline;
  }
  &:hover {
    color: inherit;
    text-decoration: underline;
  }
  &:visited {
    text-decoration: none;
  }
}


/* global button style */
input[type='button'] {
  border: none;
  width: 110px;
  font-size: $button-font-size;
  height: 21px;
  padding-top: 2px;
  border-radius: 4px;
  line-height: initial;
  text-transform: capitalize;

  &.black {
    background: black;
    color: white;
  }
  &.light-gray {
    background: $theme-light-gray;
    color:white;
  }
  &.red {
    background: $theme-red-w-opacity;
    color: white;
  }
  &:hover{

  }
  &:visited{

  }
  &[disabled=disabled]{
    opacity: .2;
  }
}

.gray-sq-btn {
  padding: 5px 10px;
  border: 1px solid black;
  background: white;
  color: black;
  display: inline-block;
  cursor: pointer;
  @include box-shadow(2px, 2px, 3px, #C7C7C7);
  &:hover {
    @include box-shadow(5px, 5px, 7px, #C7C7C7);
  }
  &:active {
    transform: translateY(2px);
  }
}

/* global textbox style */
input[type='text'] {
  color: black;
  height: 25px;
  max-height: 28px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: $button-font-size;
  padding: 0 10px;

  &.w-shadow {

  }

  @media #{$media-phone} {
    font-size: 16px;
  }
}



/* placeholder text */
::-webkit-input-placeholder {
  text-align: center;
  font-size: $button-font-size;
}
:-moz-placeholder { /* Firefox 18- */
  text-align: center;
  font-size: $button-font-size;
}
::-moz-placeholder {  /* Firefox 19+ */
  text-align: center;
  font-size: $button-font-size;
}
:-ms-input-placeholder {
  text-align: center;
  font-size: $button-font-size;
}

/* popovers */
.popover.top[type=warning]{
  border:1px solid red;
}
.popover.top[type=warning] .arrow {
  border-top-color: red;
}
.popover.top .arrow:after {
  border-top-color: white;
}
.popover-text {
  display: none !important;
}
.popover-content {
  color: black;
}

/* spinner */
.fa-spin {

}

/* datetime pickers */
.datetime-picker {
  .calendar-wrapper {
    padding: 2px 12px;
  }
}


/* modal */
.modal {
  .modal-header {
    padding: 10px 10px;
    font-weight: bold;
  }

  .modal-body{
    text-align: center;
    input[type='text'], input[type='email'], textarea{
      width: 80%;
      margin-bottom: 10px;
    }
  }

  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: $body-font-size;
    .fa-close {
      font-size: 15px;
      padding: 2px 0;
    }
  }
}

/* positioning */
.f-left {
  float: left;
}
.f-right {
  float: right;
}


/*
 * Import Site Styles
 */
/* pages */
@import "pages/about";
@import "pages/bhrt";
@import "pages/busServices";
@import "pages/contact";
@import "pages/drtice";
@import "pages/faq";
@import "pages/forms";
@import "pages/index";
@import "pages/insurance";
@import "pages/locations";
@import "pages/medicalDirector";
@import "pages/privacyPolicy";
@import "pages/providers";
@import "pages/screening";
@import "pages/services";
/* partials */
@import "partials/footer";
@import "partials/header";
@import "partials/locationSummary";
@import "partials/provider";
@import "partials/subHeader";
