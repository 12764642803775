$loc-search-height: 60px;

#pageInsurance {
  width: 70%;
  margin: 0 auto;

  .insurance-search {
    color: white;
    text-align:center;
    padding: 15px 10px;
    background: $theme-blue;

    > div {
      display: inline-block;
      margin: 2px 0;
    }
    .entry {
      padding-left: 5px;
    }
    input {
      width:200px;
    }
    .search-icon{
      position: relative;
      padding-left:5px;
    }
    .fa {
      color:#444342;
      font-size: 25px;
      @include position(relative, 5px, null, null, 0);
    }
  }

  #providersTable {
    margin-top: 16px;
    border-collapse: collapse;
    background-color: white;
    width: 100%;

    th, td {
      text-align: left;
      padding: 12px;
    }
    .img-wrap img {
      height: 35px;
      max-width: 80%;
    }
  }

  .no-results{
    text-align: center;
    margin-top: 20px;
    display: none;
    background: white;
    padding: 20px 0;
    font-size: $content-title-font-size;
  }

  @media #{$media-tablet}{

  }

  @media #{$media-tablet-n-phone}{

  }

  @media #{$media-phone}{
    width: 100%;
  }
}

