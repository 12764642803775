#pageFaq {
  .faq-title {
    font-size: $page-sub-title-font-size;
    font-weight: 600;
    width: 470px;
    margin: 20px auto 0 auto;
    text-align: center;
    text-shadow: 2px 4px 3px rgba(0,0,0,0.3);
  }
  .faq-wrapper {
    .faq-content-wrap {
      margin: 15px 150px;
      .question {
        position: relative;
        background-color: $theme-blue;
        margin-bottom: 15px;
        min-height: 40px;
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
        cursor: pointer;
        padding: 12px 35px 12px 8px;
        .question-description {
          color: white;
        }
        .plus-sign {
          @include position(absolute, $top:0px,$right:0px);
          margin: 5px 10px 5px 5px;
          padding: 2px 2px 2px 10px;
          border-radius: 50%;
          background-color: #80CBC4;
          color: white;
          box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
          width: 27px;
          height: 27px;
        }
        .minus-sign {
          @include position(absolute, $top:0px,$right:0px);
          margin: 5px 10px 5px 5px;
          padding: 2px 2px 2px 10px;
          border-radius: 50%;
          background-color: $theme-blue;
          color: white;
          box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
          width: 27px;
          height: 27px;
          display: none;
          // display: none;
        }
      }
      .question:hover{
        background-color: $theme-red-w-opacity;
      }
      .question.expanded {
        background-color: #95989A;
      }
      .answer {
        background-color: white;
        box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
        padding: 20px 30px 20px 50px;
        display: none;
        .large-A {
          color: #80CBC4;
          font-size: $page-sub-title-font-size;
        }
        .answer-description {
          color: $theme-light-gray;
          padding-left: 43px;
        }
      }
    }
  }

  .faq-content {

  }

  .faq-hours {
    .name {
      font-size: $content-title-font-size;
      font-weight: bold;
      text-decoration: underline;
    }
    .hrGroupName {
      font-weight: bold;
    }
  }

  .faq-services {
    .call-911 {
      font-weight: bold;
      color: black;
    }
  }

  @media #{$media-tablet}{
    .faq-wrapper {
      margin: 15px -140px;
    }
    .answer {
      padding: 20px 30px 20px 30px;
    }
  }

  @media #{$media-tablet-n-phone}{

    }


  @media #{$media-phone}{
    .faq-wrapper {
      margin: 15px -165px;
    }
    .faq-title {
      width: 270px;
    }
    #answer {
      padding: 20px 30px 20px 30px;
    }
    .large-A{
    }
  }
}

