$provider-square-margin-top: 25px;
$provider-name-square-height:30px;

#pageProviders {
  @include side-pad(80px !important);

  .provider-list {

    .details {
      display: none;
    }

    &.visible-xs{
      .provider-row > div {
        margin-top: $provider-square-margin-top;
      }
    }

    .provider-row:not(:first-child) {
      margin-top: $provider-square-margin-top;
    }
  }

  @media #{$media-tablet}{

  }

  @media #{$media-tablet-n-phone}{

  }

  @media #{$media-phone}{
    @include side-pad(10px !important);
  }
}
