.page-sub-header {

  @include position(relative);

  .content-wrap {
    text-align: center;
    width: 75%;
    margin: 10px auto 20px auto;

    .title {
      font-size: $page-sub-title-font-size;
      color: $theme-dark-gray;
      margin-bottom:5px;
    }
    .description {

    }
  }

  @media #{$media-tablet} {
    margin-right: -$body-gutters-size;
    margin-left: -$body-gutters-size;

    .content-wrap {
      width: 80%;
    }
  }

  @media #{$media-phone} {
    margin-right: -$body-gutters-size-phone;
    margin-left: -$body-gutters-size-phone;

    .content-wrap {
      width: 90%;
    }
  }

}

/*.page-sub-header {
  height: 100px;
  border-bottom: 1px solid black;
  @include position(relative);

  .img-wrap {
    @include position(absolute,$top:0,$right:0,$bottom:0,$left:0);
    background-image: url('/images/stethescope-md.jpg');
    background-size: cover;
    background-position: center 100px;

    .opaque-layer {
      background-color: rgba(47,137,186,.4);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .content-wrap {
    text-align: center;
    @include position(absolute,$top:0,$right:0,$bottom:0,$left:0);

    .title {
      font-size: $page-sub-title-font-size;
      color: white;
      font-family: helvetica;
      text-shadow: 2px 2px #7e7e7e;
    }
    .description {
      padding: 0 200px;
    }
  }

  @media #{$media-tablet} {
    margin-right: -$body-gutters-size;
    margin-left: -$body-gutters-size;
  }

  @media #{$media-phone} {
    margin-right: -$body-gutters-size-phone;
    margin-left: -$body-gutters-size-phone;
  }

}
*/