$service-img-width: 80px;
$content-spacing: 7px;

#pageBhrt {

  .services-wrapper {
    width: 80%;
    margin: 0 auto;
  }

  .service {
    background: white;
    padding: 10px 0px;
    border: 2px solid transparent;
    position:relative;

    &:hover {
      @include box-shadow(5px, 5px, 7px, #C7C7C7);
    }
    &.expanded {
      @include box-shadow(5px, 5px, 7px, #C7C7C7);
      border-color: transparent;
    }
    &:not(:first-child){
      margin-top:15px;
    }
    .left-border {
      @include position(absolute, 0,auto,0,-2px);
      width: 5px;
    }
    &:nth-child(even) .left-border{
      background: $theme-blue;
    }
    &:nth-child(odd) .left-border{
      background: $theme-red;
    }

    .img-wrap {
      display: inline-block;
    }
    .content-wrap {
      display: inline-block;
      .title {
        font-size: $content-title-font-size;
        margin-bottom: $content-spacing;
      }
      .description {
        margin-bottom: $content-spacing;
      }
      .more {
        font-size: 11px;
        color: $theme-blue;
      }
      .item {
        display: none;
      }
      .item-title{
        margin-top: $content-spacing;
        margin-bottom: 5px;
        color: $theme-dark-gray;
        font-weight:500;
      }
      .item-desc {
        margin-bottom: 5px;
      }
      .item-bullet {
        margin-left: 15px;
        margin-bottom: 5px;
      }
      .less {
        font-size: 11px;
        color: $theme-red;
      }
    }
  }

  .s-bottom {
    padding-top:25px;
    text-align:center;
  }

  @media #{$media-tablet}{

  }

  @media #{$media-tablet-n-phone}{
    .services-wrapper {
      width: 100%;
    }
  }

  @media #{$media-phone}{

  }
}
