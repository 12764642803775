$content-width: 300px;

#pageForms {
  width: 70%;
  margin: 0 auto;

  .forms-container {
    padding: 10px;
    text-align:center;

    .form-image-container {
      width: $content-width;
      margin: 0 auto;
      .form-img {
        border: none;
        width: 300px;
        height: 200px;
      }
    }

    button {
      font-size: 13px;
      width: $content-width;
      display: inline-block;
      margin-top: 20px;
    }
    .forms-description {
      h2 {
        font-size: $page-sub-title-font-size;
        text-align: center;
        margin: 30px 0 15px 0;
      }
      .description-forms {
        font-size: $body-font-size;
        text-align: center;
        margin: 15px 0 30px 0;
      }
    }
    .forms-link {
      text-align: center;
      border: 2px solid #DF5A5A;
      background-color: white;
      width: 240px;
      padding: 10px;
      margin: 0 auto;
    }
  }

  .insurance-portal-container {
    .insurance-box {
      border: 2px solid black;
      background-color: white;
      height: 500px;
      width: 400px;
      margin: 55px auto 0 auto;
      padding: 10px;
    }
  }


  @media #{$media-tablet}{
    width: 90%;
  }

  @media #{$media-tablet-n-phone}{

  }

  @media #{$media-phone}{
    width: 100%;

    .form-image-container, button {
      width: 100% !important;
    }
  }
}
