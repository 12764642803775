#pageDrTice {
  .medical-director-profile {
    width: 85%;
    margin: 0 auto;
  }
  .meet-header {
    background: $theme-blue;
    height: 70px;
    line-height: 70px;
    text-align:center;
    color:white;
    font-size: $content-title-font-size;
  }
  .content-wrap {
    background: $theme-light-gray-w-opacity;
    padding:20px;
  }
  .img-wrap {
    .doctor-img {
      padding: 15px;
    }
    img {
      display: block;
      margin: 0 auto;
      width: 45%;
      max-width: 250px;
    }
  }
  .details-wrap {
    .details-content{
      padding: 10px;
      text-align:center;
      .doctor-name {
        font-size: $page-sub-title-font-size;
      }
      .title {
        font-size: $content-title-font-size;
        font-weight: bold;
        margin-top: 10px;
      }
      .value {
        margin-top: 5px;
        margin-left: 5px;
        text-align: left;
      }
    }
  }

  .s-bottom {
    padding-top:25px;
    text-align:center;
  }

  @media #{$media-tablet}{
    .medical-director-profile {
      width: 90%;
    }
  }

  @media #{$media-tablet-n-phone}{

  }

  @media #{$media-phone}{
    .medical-director-profile {
      width: 100%;
    }
  }
}
