/// Adds a browser prefix to the property
/// @param {*} $property Property
/// @param {*} $value Value

@mixin css3-prefix($property, $value) {
  -webkit-#{$property}: #{$value};
  -khtml-#{$property}: #{$value};
  -moz-#{$property}: #{$value};
  -ms-#{$property}: #{$value};
  -o-#{$property}: #{$value};
  #{$property}: #{$value};
}

/// Box Shadow
/// @param {Size} $x [2px] - X
/// @param {Size} $y [2px] - Y
/// @param {Size} $blur [5px] - Blur
/// @param {Color} $color [rgba(0,0,0,.4)] - Color
/// @param {Boolean} $inset - Inset

@mixin box-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0,0,0,.4), $inset: "") {
  @if ($inset != "") {
    @include css3-prefix('box-shadow', $inset $x $y $blur $color);
  } @else {
    @include css3-prefix('box-shadow', $x $y $blur $color);
  }
}

/// Text Shadow
/// @param {Size} $x [2px] - X
/// @param {Size} $y [2px] - Y
/// @param {Size} $blur [2px] - Blur
/// @param {Color} $color [rgba(0,0,0,.4)] - Color
@mixin text-shadow($x: 2px, $y: 2px, $blur: 5px, $color: rgba(0,0,0,.4)) {
  text-shadow: $x $y $blur $color;
}

/// Opacity
/// @param {Double} $opacity [0.5] - Opacity
/// @require {mixin} css3-prefix

@mixin opacity($opacity: 0.5) {
  $opacityMultiplied: ($opacity * 100);

  filter:         alpha(opacity=$opacityMultiplied);
  -ms-filter:     "progid:DXImageTransform.Microsoft.Alpha(Opacity=" + $opacityMultiplied + ")";
  @include css3-prefix('opacity', $opacity);
}

/// Shorthand mixin for offset positioning
/// @param {String} $position - Either `relative`, `absolute` or `fixed`
/// @param {Length} $top [null] - Top offset
/// @param {Length} $right [null] - Right offset
/// @param {Length} $bottom [null] - Bottom offset
/// @param {Length} $left [null] - Left offset
@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin side-pad($left, $right: $left){
  padding-left:$left;
  padding-right: $right;
}
