$current-hrs-text-size: 12px;

.loc-summary {
  display:inline-block;
  margin: 0 auto;

  .loc-img {
    float:left;
    display: inline-block;
    margin-right: 15px;

    img {
      height: 90px;
      margin: 0 auto;
    }
  }
  .loc-details {
    text-align: left;
    display:inline-block;

    > div {
      margin-top:2px;
      margin-bottom: 5px;
    }
    .name {
      font-weight: bold;
      color: $theme-blue !important;
    }
    .hr-group {
      .description {
        font-weight: bold;
      }
      .current-hrs {
        font-size: $current-hrs-text-size;
      }
    }
  }
  .phone .fa,.fax .fa{
    font-size:15px;
  }

  @media #{$media-tablet-n-phone}{
    .loc-img {
      display: block;
      float:none;
    }
    .loc-details{
      text-align: center;
    }
  }
}

