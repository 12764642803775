.provider-wrapper {
  .details {
    text-align: center;
    margin-top: 6px;
    padding: 10px;
    color: #534F4F;
    background-color: rgba(233, 62, 62, 0.78);
    font-weight: 200;
    box-shadow: 2px 2px 5px #95989A;
    .descriptor {
      margin-bottom: 10px;
      .title {
        font-weight: 500;
      }
    }
  }
  .name {
    background-color: #2789BA;
    height: 47px;
    border-radius: 5px;
    margin-bottom: 10px;
    box-shadow: 2px 2px 5px #95989A;
    color: white;
    text-align: center;
    font-size:$content-title-font-size;
    height: $provider-name-square-height;
    line-height:$provider-name-square-height;

    &:hover {
      background-color: $theme-red-w-opacity;
    }

  }
  &.selected .name {
    background-color: $theme-red-w-opacity !important;
  }


  .avatar {
    background: #534F4F;
    height: 140px;
    box-shadow: 2px 2px 5px #95989A;
    text-align: center;
    img {
      margin-top: 8px;
      width: 124px;
      height: 124px;
      border-radius:50%;
      border: 4px solid rgba(233, 62, 62, 0.78);
    }
  }

}
